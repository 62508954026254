// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/training-asset/config.ts"
);
import.meta.hot.lastModified = "1731679026373.6436";
}
// REMIX HMR END

export const config = {
    path: '/dashboard/settings/training-assets'
}